import { createMuiTheme } from '@material-ui/core/styles'
const defaultTheme = createMuiTheme({})

export const baseThemeData = {
  custom: {
    colors: {
      black: '#000',
      blue: '#10224E',
      white: '#fff',
      yellow: '#FCCC4B',
      backgroundYellow: '#FFC433',
      beige: '#FFF8F0',
      green: '#315741',
      gray: '#707070',
      textBlue: '#0060C7',
      lineGray: '#E0E0E0'
    },
    layout: {
      gap: `${defaultTheme.spacing(2)}px`,
      navHeight: 66,
      navHeightMobile: 58
    }
  },
  palette: {
    primary: {
      main: '#000'
    },
    secondary: {
      main: '#FCCC4B'
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    },
    MuiButton: {
      disableElevation: true,
      variant: 'contained',
      color: 'primary'
    },
    MuiLink: {
      color: 'inherit',
      underline: 'always'
    },
    MuiListItem: {
      disableGutters: true
    },
    MuiTabs: {
      variant: 'scrollable',
      scrollButtons: 'auto'
    }
  },
  typography: {
    h1: {
      fontWeight: 100,
      fontSize: '2.5rem',
      whiteSpace: 'pre-wrap',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 50
      },
      [defaultTheme.breakpoints.down('xs')]: {
        fontSize: 32,
        marginBottom: '.75em'
      }
    },
    h2: {
      fontSize: '0.7777777778rem',
      textTransform: 'uppercase',
      fontWeight: 500,
      letterSpacing: '.03em',
      whiteSpace: 'pre-wrap',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 12
      }
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.5,
      whiteSpace: 'pre-wrap',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 16
      }
    },
    caption: {
      fontSize: '0.7777777778rem',
      fontWeight: 300,
      whiteSpace: 'pre-wrap',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 14
      },
      [defaultTheme.breakpoints.down('xs')]: {
        fontSize: 11
      }
    },
    body1: {
      fontWeight: 300,
      marginBottom: '1.5em',
      fontSize: '1rem',
      whiteSpace: 'pre-wrap',
      '& strong': {
        fontWeight: 500
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 18
      },
      [defaultTheme.breakpoints.down('xs')]: {
        fontSize: 16
      }
    },
    subtitle1: {
      fontWeight: 300,
      marginBottom: '1.5em',
      color: '#000',
      fontSize: '1.25rem',
      lineHeight: 1.333,
      whiteSpace: 'pre-wrap',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 18
      }
    }
  },
  transitions: {
    duration: {
      enteringScreen: 425,
      leavingScreen: 395
    }
  },
  overrides: {
    MuiAccordion: {
      root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderBottom: '1px solid #707070',
        '&.Mui-expanded': {
          margin: 0
        },
        '&::before': {
          display: 'none'
        },
        '& + *:not(.MuiAccordion-root)': {
          marginTop: '2em'
        },
        [defaultTheme.breakpoints.down('sm')]: {
          borderBottomColor: '#E0E0E0'
        }
      }
    },
    MuiAccordionSummary: {
      root: {
        padding: defaultTheme.spacing(3, 0),
        minHeight: 0,
        [defaultTheme.breakpoints.down('sm')]: {
          padding: 0
        }
      }
    },
    MuiAccordionDetails: {
      root: {
        padding: '0 0 2em',
        [defaultTheme.breakpoints.down('sm')]: {
          padding: '0 0 1.2em',
          '& .MuiAccordionDetails-root': {
            paddingBottom: '.8em'
          }
        }
      }
    },
    MuiButton: {
      root: {
        padding: '1em 2em',
        marginTop: '1em',
        borderRadius: '2em',
        marginBottom: '2em',
        fontSize: '0.7777777778rem',
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 12
        }
      }
    },
    MuiList: {
      root: {
        listStyle: 'disc',
        marginLeft: '1em',
        '& ul,& ol': {
          marginTop: 0,
          marginBottom: 0,
          paddingBottom: 0,
          '& li:last-of-type': {
            paddingBottom: 0
          }
        },
        '&.ol': {
          listStyle: 'none',
          counterReset: 'ordinal-counter',
          '&>li::before': {
            content: 'counter(ordinal-counter, decimal) "."',
            counterIncrement: 'ordinal-counter',
            position: 'absolute',
            left: -19,
            top: 3,
            fontWeight: 300
          }
        },
        '& .MuiList-root.ol': {
          listStyle: 'none',
          counterReset: 'nested-ordinal-counter',
          '&>li::before': {
            content: 'counter(nested-ordinal-counter, lower-alpha) "."',
            counterIncrement: 'nested-ordinal-counter',
            position: 'absolute',
            left: -19,
            top: 0,
            fontWeight: 300
          }
        }
      },
      padding: {
        padding: 0,
        marginTop: defaultTheme.spacing(1),
        marginBottom: defaultTheme.spacing(1)
      }
    },
    MuiListItem: {
      root: {
        display: 'list-item',
        paddingTop: 0,
        '&>.MuiTypography-root:only-child': {
          marginBottom: 0
        }
      }
    },
    MuiTabs: {
      root: {
        minHeight: 0
      },
      indicator: {
        height: 3
      }
    },
    MuiTab: {
      root: {
        fontSize: '0.7777777778rem',
        fontWeight: 500,
        lineHeight: 1.3,
        minWidth: 0,
        maxWidth: 'none',
        padding: `0 0 5px 0`,
        minHeight: 0,
        marginRight: defaultTheme.spacing(3),
        [defaultTheme.breakpoints.up('sm')]: {
          minWidth: 0
        },
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 12
        }
      }
    },
    MuiTabScrollButton: {
      root: {
        position: 'relative',
        top: -2,
        [defaultTheme.breakpoints.down('sm')]: {
          top: -4
        },
        '&.Mui-disabled': {
          width: 0
        }
      }
    }
  }
}

const theme = createMuiTheme(baseThemeData)

export default theme
